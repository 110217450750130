import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals'
import { computed, inject } from '@angular/core'
import { EService, IServiceInsuranceConfig, IServiceTree } from '@modules/app/interfaces/site-tree'
import { catchError, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { SiteTreeResource } from '@modules/app/resources/site-tree.resource'

type IStateApp = {
  isLoading: boolean
  serviceTree: IServiceTree | null
}
const initialStateApp: IStateApp = {
  isLoading: false,
  serviceTree: null,
}

export const ServiceStore = signalStore(
  { providedIn: 'root' },
  withState(initialStateApp),
  withComputed(store => ({
    serviceTree: computed(() => {
      return store.serviceTree()
    }),
    insurancesIsActive: computed(() => {
      return store.serviceTree()?.[EService.INSURANCES]?.active
    }),
    insurancesConfig: computed(() => {
      const config = store.serviceTree()?.[EService.INSURANCES]
      return {
        active: config?.active,
        carQuotingEnabled: config?.car_quoting_enabled,
        commingSoon: !config?.car_quoting_enabled,
      } as IServiceInsuranceConfig
    }),
  })),
  withMethods((store, siteTreeResource = inject(SiteTreeResource)) => {
    return {
      putLoading(loading: boolean) {
        patchState(store, {
          isLoading: loading,
        })
      },
      setServiceTree: (serviceTree: IServiceTree) => {
        patchState(store, {
          serviceTree,
        })
      },
      getServiceTree() {
        return siteTreeResource.servicesTree().pipe(
          catchError(() => of(null)),
          map(serviceTree => {
            if (!serviceTree) {
              return
            }
            patchState(store, {
              serviceTree,
            })
          })
        )
      },
    }
  })
)
