export interface ISiteTreeOption {
  label?: string
  icon?: string
  show: boolean
  badge?: string
}

export interface ISiteTree {
  menu: ISiteTreeMenu
  tabs: ISiteTreeTabs
  header: ISiteTreeHeader
  home: ISiteTreeHome
}

export interface IServiceTreeConfig {
  active: boolean
  label: string
  badge: string
}

export interface IServiceInsuranceConfig extends IServiceTreeConfig {
  carQuotingEnabled: boolean
  commingSoon: boolean
}

export interface IServiceInsuranceConfigRequest extends IServiceTreeConfig {
  car_quoting_enabled: boolean
}

export enum EService {
  INSURANCES = 'insurances',
}

export interface IServiceTree {
  [EService.INSURANCES]: IServiceInsuranceConfigRequest | null
}

export interface ISiteTreeMenu {
  show: boolean | Partial<ISiteTreeOption>
  profile: boolean | Partial<ISiteTreeOption>
  insurance: boolean | Partial<ISiteTreeOption>
  payment_service: boolean | Partial<ISiteTreeOption>
  topups: boolean | Partial<ISiteTreeOption>
  benefits: boolean | Partial<ISiteTreeOption>
  gift_cards: boolean | Partial<ISiteTreeOption>
  redeem: boolean | Partial<ISiteTreeOption>
  refer: boolean | Partial<ISiteTreeOption>
  about: boolean | Partial<ISiteTreeOption>
  history: boolean | Partial<ISiteTreeOption>
  monto_cash: boolean | Partial<ISiteTreeOption>
  shop: boolean | Partial<ISiteTreeOption>
  health: boolean | Partial<ISiteTreeOption>
}

export interface ISiteTreeTabs {
  show: boolean
  home: boolean
  payment_service: boolean
  topups: boolean
  benefits: boolean
  history: boolean
  recommend: boolean
  shop: boolean
}

export interface ISiteTreeHeader {
  show: boolean
  notifications: boolean
  guide: boolean
  menu: boolean
  history: boolean
}

export interface ISiteTreeHome {
  withdrawal: boolean
  withdrawal_cash: boolean
  insurance: boolean
  payment_qr: boolean
  payment_qr_generate: boolean
  payment_qr_scan: boolean
  benefits: boolean
  news: boolean
  shop: boolean
}

export interface ISiteTreeState {
  siteTree: ISiteTree | null
  loading: boolean
}
